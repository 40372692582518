import axios from 'axios';
import getApiUrl from '../helpers/apiUrls'
import { authHeader } from '../helpers/authHeader';
import * as commonActions from '../store/common/actions'
import * as type from '../store/shipment/actionTypes'
import quoteService from './quoteService';
import utilCommon from '../helpers/utilCommon';
import * as actions from '../store/shipment/actions'
import * as quoteType from '../store/quote/actionTypes'
import * as actionsQuote from '../store/quote/actions'
import saveAs from 'file-saver';
import { templates } from '../helpers/templates';
import lodash from "lodash";
import {setDocumentInfo, setProductImages, setViewImage} from "../store/shipment/actions";

export const getShipmentDetail = (dispatch, inputDTO, lang, dictionaries) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'shipmentDetail');
    url = url + `?shipmentUuid=${inputDTO.shipmentUuid}`
    dispatch(commonActions.setLoader(true))
    return axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_SHIPMENT_DETAIL, shipmentDetails: response.data.content })
            }
            if (response.data.content.bolStatus !== undefined) {
                dispatch(actions.setBolStatus(inputDTO.shipmentUuid, response.data.content.bolStatus));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });

};

//get tracking info
export const getTrackingInfo = (dispatch, lang, dictionary, inputDTO,flag, callback = null) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'tracking');
    dispatch(commonActions.setLoader(true))
    url = url + `?shipmentUuid=${inputDTO.shipmentUuid}`
    axios['get'](url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.success) {
                if (response.data.content.shipmentTrackingInfo.length > 0) {
                    if(flag){
                        dispatch({type:type.TRACKING_INFO_AVAILABLE,hasTrackingInfo:true})
                        getShipmentDetail(dispatch,inputDTO, lang, dictionary )
                    }

                    dispatch({ type: type.SET_TRACKING_HEADER, trackingHeader: response.data.content.shipmentTrackingInfo[0] })
                    getTrackingDetails(dispatch, lang, dictionary, { shipmentTrackingUuid: response.data.content.shipmentTrackingInfo[0].shipmentTrackingUuid })
                    dispatch(actions.setTrackingSearchStatus(true));
                } else{
                    dispatch(actions.setTrackingSearchStatus(false));
                    // utilCommon.showNotification('99989', dictionary, lang)
                }

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        })
        .finally(() =>{
            if ( callback ){
                callback();
            }
        });;
};

export const getTrackingInfoForWidget = (inputDTO, form) =>{
    form.change('widgetStatus', 1);

    Promise.all([
        getSimpleTrackingInfo(inputDTO),
        getShipmentDetailSimple(inputDTO),
    ])
        .then(
            response=>{
                let widgetStatus = 2;
                form.reset();
                response.map(item=>{
                    const data = lodash.omit(item, ['success']);
                    Object.keys(data).map(key=> {
                        form.change(key, item[key])
                    });

                    if (item.success === false){
                        widgetStatus = 3

                    }
                })

                form.change('widgetStatus', widgetStatus);

            },
            e=>{
                form.reset();
                form.change('widgetStatus', 3);
            }
        )
}

//get tracking info
const getSimpleTrackingInfo = (inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'tracking');
    url = url + `?shipmentUuid=${inputDTO.shipmentUuid}`

    const failResponse = { success: false };
    return axios['get'](url, params)
        .then(function(response) {
            if (response.data.success) {
                if (response.data.content.shipmentTrackingInfo.length > 0) {
                    return {
                        success: true,
                        shipmentTrackingInfo: response.data.content.shipmentTrackingInfo.shift()
                    };
                }
            }

            return failResponse;

        }).catch(e=> { return failResponse});

};

const getShipmentDetailSimple = (inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'shipmentDetail');
    url = url + `?shipmentUuid=${inputDTO.shipmentUuid}`
    const failResponse = { success: false };
    return axios.get(url, params).then(function(response) {
        if (response.data.success) {
            return {
                success: true,
                shipmentDetail: response.data.content.shipmentList.shift()
            };
        }

        return failResponse;

    }).catch(e=> { return failResponse});

};

export const getTrackingDetailsSimple = (inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'trackingDetails');
    url = url + `?shipmentTrackingUuid=${inputDTO.shipmentTrackingUuid}&sortColumn=stopNumber&sortOrder=asc`
    axios['get'](url, params);
};

// get tracking details

export const getTrackingDetails = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'trackingDetails');
    dispatch(commonActions.setLoader(true))
    url = url + `?shipmentTrackingUuid=${inputDTO.shipmentTrackingUuid}&sortColumn=stopNumber&sortOrder=asc`
    axios['get'](url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.success) {
                dispatch({ type: type.SET_TRACKING_INFO, trackingInfoList: response.data.content.shipmentTrackingDetails })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// post tracking info
export const postTrackingInfo = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'tracking');
    dispatch(commonActions.setLoader(true))
    axios['post'](url, { shipmentUuid: inputDTO.shipmentUuid }, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                // utilCommon.showNotification(response.data.message, dictionary, lang);
                getTrackingInfo(dispatch, lang, dictionary, { shipmentUuid: inputDTO.shipmentUuid })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });



};

// get quote results

export const getQuoteResults = (currentLanguage, dictionary, inputDTO, shipmentUuid, rateQuery) => (dispatch, getState) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('quote', 'getQuoteResults');
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (response.data.content.responseData.errors) {
                    response.data.content.responseData.errors.map(x => {
                        utilCommon.showNotification(x.message, dictionary, currentLanguage, true)
                    })
                } else {
                    dispatch({ type: quoteType.SET_QUOTE_STEP, quoteStep: 2 })
                    if (rateQuery) {
                        dispatch({ type: quoteType.SET_QUOTE_QUERY, quoteQuery: rateQuery })
                    }

                    const quoteResults = {
                        quoteResults: response.data.content.responseData.rateQuotes,
                        quoteRequestUuid: response.data.quoteRequestUuid,
                        shipmentUuid: shipmentUuid ? shipmentUuid : null,
                        template: response.data.content.responseData.template ? response.data.content.responseData.template : templates.full
                    };

                    dispatch(actionsQuote.setQuoteResults(quoteResults));
                    sessionStorage.setItem('quoteResults', JSON.stringify(quoteResults))
                    // if (response.data.content.responseData.rateQuotes.length) {
                    // const { template } = response.data.content.responseData;
                    const template = parseInt(lodash.get(getState().accountInfo, 'customerState.customerShippingFrequency.id', 1).toString());
                      templates.handleTemplateRoute(template, Window['history']);
                    // } else {
                    //   dispatch(commonActions.setLoader(false));
                    //   utilCommon.showNotification('500', dictionaries, lang);
                    // }
                }
            } else {
                response.data.errors.map((error) => {
                    if (error.data) {
                        utilCommon.showNotification(error.data.responseData, dictionary, currentLanguage, true)
                    } else {
                        utilCommon.showNotification(error, dictionary, currentLanguage)
                    }
                })
            }
        })
        .catch((err) => {});
}




// get transaction details
export const getTransationDetails = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'transactionDetails');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid + '&sortColumn=modifiedDate&sortOrder=desc';
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_TRANSACTION_DETAIL, transactionDetails: response.data.content.shipmentTransactionDetail })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
        });
}

// get shipment charge
export const getShipmentCharge = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'shipmentCharge');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid;
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_SHIPMENT_CHARGES, shipmentCharges: response.data.content })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
        });
}


// get shipment charge
export const getTransactionCharge = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'shipmentCharge');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid;
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_TRANSACTION_CHARGES, transactionCharges: response.data.content })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
        });
}


// shipment payment
export const makeShipmentPayment = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'shipmentPayment');
    dispatch(commonActions.setLoader(true))
    return axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            // getTransactions(dispatch, lang, dictionary, inputDTO)
            getTransationDetails(dispatch, lang, dictionary, inputDTO)
            // getPayments(dispatch, lang, dictionary, inputDTO)
            // getARPaymentOptions(dispatch, lang, dictionary, inputDTO)
            getShipmentCharge(dispatch, lang, dictionary, inputDTO)
            if (response.data.success) {
                if(response.data.message == 15023){
                    utilCommon.showNotification(response.data.message, dictionary, lang)
                }
                dispatch({type:type.SET_PAYMENT_DONE,paymentDone:true})
                getShipmentDetail(dispatch, { shipmentUuid: inputDTO.shipmentUuid })
                if (response.data.content.data['3']) {
                    Object.keys(response.data.content.data['3']).map(x => {
                        if (response.data.content.data['3'][x].status) {
                            let cardDetails = inputDTO.payments.filter(y => y.paymentTypeId == '3').find(z => z.paymentProfileId == x).cardDetail
                            utilCommon.showNotification('Shipment payment with Card ' + cardDetails.type + " " + cardDetails.number + " succesfull", dictionary, lang)
                        } else {
                            let cardDetails = inputDTO.payments.filter(y => y.paymentTypeId == '3').find(z => z.paymentProfileId == x).cardDetail
                            utilCommon.showNotification('Shipment payment with Card ' + cardDetails.type + " " + cardDetails.number + " failed", dictionary, lang, true)
                        }
                    })
                }

                if (response.data.content.data['4']) {
                    if (response.data.content.data['4'].length > 0 && response.data.content.data['4'][0].status) {
                        utilCommon.showNotification("Shipment payment with cheque succesfull", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with cheque failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['5']) {
                    if (response.data.content.data['5'].length > 0 && response.data.content.data['5'][0].status) {
                        utilCommon.showNotification("Shipment payment with wire transfer succesfull", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with wire transfer failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['6']) {
                    if (response.data.content.data['6'].length > 0 && response.data.content.data['6'][0].status) {
                        utilCommon.showNotification("Shipment payment with automated clearing house (ACH) succesfull", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with automated clearing house (ACH) failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['7']) {
                    if (response.data.content.data['7'].length > 0 && response.data.content.data['7'][0].status) {
                        utilCommon.showNotification("Shipment payment with A/R account succesfull", dictionary, lang)
                        if ((inputDTO.amountDue > inputDTO.arCreditLimit) && inputDTO.amountDue < ((inputDTO.arCreditLimit + inputDTO.arGraceAmount))) {
                            utilCommon.showNotification("The customer's credit limit has been exceeded. As a one-time courtesy, FreightCenter is processing this shipment", dictionary, lang)
                        }
                    } else {
                        utilCommon.showNotification("Shipment payment with A/R account failed", dictionary, lang, true)
                    }
                }

            } else {
                if(response.data.errors[0] === 22058){
                    dispatch(commonActions.setLoader(true))
                    setTimeout(() => {
                        getTransationDetails(dispatch, lang, dictionary, inputDTO)
                        getShipmentCharge(dispatch, lang, dictionary, inputDTO);
                        dispatch(commonActions.setLoader(false))
                    }, 3000)
                }
                if(response.data.errors[0] === 22059){
                    Window['history'].push(`/customer/ftl?data=${JSON.stringify({formName:"TL",fid:"form-ftl-long"})}`)
                }
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true);
                })
            }

            return response;
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


const getMappedProduct = (product) => {
    let prod = { dimUnit: '2', weightUnit: '2' }
    prod.howManyHandlingUnits = parseInt(product.noOfHandlingUnit)
    prod.weightPerHandlingUnit = parseInt(product.itemWeight)
    prod.width = product.productWidth
    prod.length = product.productLength
    prod.height = product.productHeight

    if (product.productHeight) {
        prod.dimensionType = true
    }

    // Free dimension pallet
    if (product.packageTypeId.id == 1) {
        prod.palletCheck = true
    }

    // Fix dimension pallet
    if (product.packageTypeId.id == 2 || product.packageTypeId.id == 3 || product.packageTypeId.id == 4) {
        prod.palleteType = product.packageTypeId.id
    }

    // Crate
    if (product.packageTypeId.id == 5) {
        prod.itemType = 3
    }

    // Box
    if (product.packageTypeId.id == 6) {
        prod.itemType = 2
    }

    // Packagetypes other than pallete, crate and box
    if (product.packageTypeId.id > 6) {
        prod.otherItemType = product.packageTypeId.id
    }

    prod.dimUnit = product.productMeasurement
    prod.weightUnit = product.weightMeasurement
    prod['haz-matItem'] = product['haz-matItem'] == 1 ? true : false
    prod.stackable = product.stackableItem == 1 ? true : false
    prod.calculatedFreightClass = product.freightClassId
    return prod
}
// get quote navigation on the basis of quote status (contract conditions)
export const viewQuote = (lang, dictionary, inputDTO, history, rateQuery) => (dispatch, getState) =>{
    const params = { headers: authHeader() };
    let url = getApiUrl('quote', 'viewQuote');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (response.data.viewShipment) {
                    history.push('/customer/shipment' ,{shipmentUuid:inputDTO.shipmentUuid})
                } else {
                    dispatch({ type: quoteType.SET_QUOTE_STEP, quoteStep: 2 })
                    if (response.data.message == '12517' || response.data.message == '12515') {
                        dispatch({ type: type.SET_ERROR_MESSAGE, quoteResultErrMsg: response.data.message })
                    }

                    if (response.data.content.shipmentProductList) {
                        let rQuery = Object.assign({}, rateQuery)
                        response.data.content.shipmentProductList.map(item => {
                            rQuery.items.push(getMappedProduct(item))
                        })

                        dispatch({ type: quoteType.SET_QUOTE_QUERY, quoteQuery: rQuery })


                        const quoteResults = {
                            quoteResults: response.data.content.responseData.rateQuotes,
                            quoteRequestUuid: response.data.quoteRequestUuid,
                            shipmentUuid: inputDTO.shipmentUuid ? inputDTO.shipmentUuid : null,
                            requestedPickupStartDate: response.data.content.requestedPickupStartDate,
                            template: response.data.content.responseData.template ? response.data.content.responseData.template : templates.full
                        };

                        dispatch(actionsQuote.setQuoteResults(quoteResults));
                        sessionStorage.setItem('quoteResults', JSON.stringify(quoteResults))

                        // if (response.data.content.responseData.rateQuotes.length) {
                          quoteService.getCarriers(dispatch, lang, dictionary)
                          // const { template } = response.data.content.responseData;
                        const template = parseInt(lodash.get(getState().accountInfo, 'customerState.customerShippingFrequency.id', 1).toString());

                          templates.handleTemplateRoute(template, history);
                        // } else {
                        //   dispatch(commonActions.setLoader(false));
                        //   utilCommon.showNotification('500', dictionaries, lang);
                        // }
                    } else{
                        utilCommon.showNotification(999989, dictionary, lang)
                    }
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}

// update shipment products
export const applyCoupon = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'transaction');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.shipmentTransactionUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Coupon applied succesfully', dictionary, lang)
                getTransactionCharge(dispatch, lang, dictionary, inputDTO)
                getTransationDetails(dispatch, lang, dictionary, inputDTO)
                getShipmentCharge(dispatch, lang, dictionary, inputDTO)
            }
            else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}
// get shipment charge
export const trackShipment = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'trackShipment');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid;

    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({type:type.TRACKING_INFO_AVAILABLE,hasTrackingInfo:true})
                getTrackingInfo(dispatch, lang, dictionary, { shipmentUuid: inputDTO.shipmentUuid })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});
}

export const getDocuments = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'getDocuments');
    url += '?shipmentUuid=' + inputDTO.shipmentUuid;
    if (inputDTO.sortColumn && inputDTO.sortOrder) {
        url += '&sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder;
    }
    // dispatch(commonActions.setLoader(true))
    return axios.get(url, params)
        .then((response) => {
            // dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch(actions.setBolStatus(inputDTO.shipmentUuid, response.data.content.bolStatus));
                dispatch(actions.setDocuments(response.data.content.shipmentsDocuments, response.data.content.count, false));
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            // dispatch(commonActions.setLoader(false))
        });
};


// getBolStatusFromRedis
export const getBolStatusFromRedis = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  let url = getApiUrl('shipment', 'documentBol');
  url = url + '?shipmentIds=' + inputDTO.shipmentUuid;
  if (inputDTO.shipmentUuid.length) {
    axios.get(url, params)
      .then((response) => {
        if (response.data.success) {
            const shipments = response.data.content;
            dispatch(actions.setAllBolStatus(shipments, true));
        } else {
          response.data.errors.map((error) => {
            utilCommon.showNotification(error, dictionary, lang)
          })
        }
      })
      .catch((err) => {});
  }
};

export const downloadDocument = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  let url = getApiUrl('shipment', 'documentPreview');
  dispatch(commonActions.setLoader(true))
  url += `?shipmentDocumentUuid=${inputDTO.shipmentDocumentUuid}`;
  if (inputDTO.documentsGenerated !== undefined) {
    url += `&documentsGenerated=${inputDTO.documentsGenerated}`;
  }
  if (inputDTO.manuallyDownload) {
    url += `&manuallyDownload=1`;
  }

  axios['get'](url, params)
    .then((response) => {
      dispatch(commonActions.setLoader(false));
      if (response.data.success) {
        var binary = atob(response.data.content.fileContent.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }

        // let data = 'data:' + response.data.content.documentMimeType + ';base64,' + response.data.content.fileContent
        var blob = new Blob([view], { type: response.data.content.documentMimeType });
        saveAs(blob, response.data.content.shipmentUuid + '_' + response.data.content.documentFileName);
        utilCommon.showNotification(response.data.message, dictionary, lang);

      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false))
    });
};

export const getDocumentInfoByHash = (dispatch, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'documentInfo');
    url = url + `?hash=${inputDTO.hash}`
    dispatch(actions.setDocumentInfoLoader(true));

    return axios.get(url, params)
        .then((response) => {
            dispatch(actions.setDocumentInfo(response.data));
            dispatch(actions.setDocumentInfoLoader(false));
        })
        .catch((err) => {
            dispatch(actions.setDocumentInfoLoader(false));
        });
}

export const getShInfo = (dispatch, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'shInfo');
    url = url + `?shipmentUuid=${inputDTO}`

    return axios.get(url, params)
        .then((response) => {
            dispatch(actions.setShInfo(response.data));
        });
}

export const editShipmentName = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'editShipmentName');
    dispatch(commonActions.setLoader(true))
    return axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Shipment Name has been updated successfully', dictionary, lang, false)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const signCA = (inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'signCA');

    return axios.post(url, inputDTO, params)
        .then((response) => {
            if (lodash.get(response, 'data.success', false)) {
                utilCommon.showAgentNotification('The customer agreement has been signed', 3);
            } else {
                utilCommon.showAgentNotification('The customer agreement has not been signed', 1);
            }
        });
}

export const signCRA = (inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'signCRA');

    return axios.post(url, inputDTO, params)
        .then((response) => {
            if (lodash.get(response, 'data.success', false)) {
                utilCommon.showAgentNotification('The carrier rate confirmation has been signed successfully', 3);
            } else {
                utilCommon.showAgentNotification('Signing the carrier rate confirmation failed', 1);
            }
        }, error => {
            if (!lodash.isEmpty(lodash.get(error, 'response.data.errors', ''))) {
                utilCommon.showAgentNotification(lodash.get(error, 'response.data.errors'), 1);
            } else {
                utilCommon.showAgentNotification('Signing the carrier rate confirmation failed', 1);
            }
        }).catch(error => {
            if (!lodash.isEmpty(lodash.get(error, 'response.data.errors', ''))) {
                utilCommon.showAgentNotification(lodash.get(error, 'response.data.errors'), 1);
            } else {
                utilCommon.showAgentNotification('Signing the carrier rate confirmation failed', 1);
            }
        });
}

export const downloadCRA = (dispatch, lang, dictionary, inputDTO, callback = null) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'cra');
    url = `${url}?shipmentUuid=${inputDTO.shipmentUuid}&bol=1`;

    if (inputDTO.documentsGenerated !== undefined) {
        url += `&documentsGenerated=${inputDTO.documentsGenerated}`;
    }

    if (inputDTO.shipmentDocumentUuid !== undefined) {
        url += `&shipmentDocumentUuid=${inputDTO.shipmentDocumentUuid}`;
    }

    if (inputDTO.cra !== undefined) {
        url += `&cra=${inputDTO.cra}`;
    }

    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch(setDocumentInfo(response.data.content));
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        })
        .finally(() => {
            if (callback) {
                callback();
            }
        });
}

export const uploadImage = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'uploadImage');
    dispatch(commonActions.setLoader(false));
    dispatch(commonActions.setLoader(true));
    const reader = new FileReader();
    reader.onload = (e) => {
        const fileAsBinaryString = e.target.result.split(',')[1];
        const data = {
            shipmentUuid: inputDTO.shipmentUuid,
            shipmentProductUuid: inputDTO.shipmentProductUuid,
            name: inputDTO.image.name,
            type: inputDTO.image.type,
            imageContent: fileAsBinaryString
        }
        axios.post(url, data, params)
            .then((response) => {
                dispatch(commonActions.setLoader(false))
                if (response.data.success) {
                    if (lodash.get(inputDTO, 'showSuccessInfo', false)) {
                        utilCommon.showNotification("Photo upload was successful", dictionary, lang, false);
                        getDocuments(dispatch, lang, dictionary, {shipmentUuid: inputDTO.shipmentUuid})
                    } else {
                        getProductImages(dispatch, lang, dictionary, {shipmentUuid: inputDTO.shipmentUuid})
                    }
                } else {
                    response.data.errors.map((error) => {
                        console.info(error)
                    })
                    utilCommon.showNotification("We're sorry, there was a problem uploading your image. Please try again or contact customer support.", dictionary, lang, true);
                }
            })
            .catch((err) => {
                dispatch(commonActions.setLoader(false));
            });
    };

    reader.readAsDataURL(inputDTO.image);
};

export const getProductImages = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'getProductImages');
    url = `${url}?shipmentUuid=${lodash.get(inputDTO, 'shipmentUuid', '0')}`;
    if (lodash.get(inputDTO, 'shipmentDocumentId', 0) != 0) {
        url = `${url}&shipmentDocumentId=${lodash.get(inputDTO, 'shipmentDocumentId')}`;
    }

    if (lodash.get(inputDTO, 'main', 0) === 1) {
        url = `${url}&main=1`;
    }

    dispatch(commonActions.setLoader(true));
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success ) {
                if (lodash.get(inputDTO, 'main', 0) !== 1) {
                    dispatch(setProductImages(response.data.data));
                } else {
                    dispatch(setViewImage(response.data.data));
                }
            } else {
                response.data.errors.map((error) => {
                    console.info(error)
                })

                utilCommon.showNotification("We're sorry, there was a problem showing your image. Please try again or contact customer support.", dictionary, lang, false);
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
        });

};

export const deleteImage = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'deleteImage');
    dispatch(commonActions.setLoader(true));
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Image has been deleted!', dictionary, lang, false);
                getProductImages(dispatch, lang, dictionary, {shipmentUuid: inputDTO.shipmentUuid})
            } else {
                response.data.errors.map((error) => {
                    console.info(error)
                })
                utilCommon.showNotification("We're sorry, there was a problem deleting your image. Please try again or contact customer support.", dictionary, lang, false);
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
        });

};

export default {
    getShipmentDetail,
    getTrackingDetails,
    getTrackingInfo,
    postTrackingInfo,
    getQuoteResults,
    getTransationDetails,
    getShipmentCharge,
    makeShipmentPayment,
    viewQuote,
    applyCoupon,
    getTransactionCharge,
    trackShipment,
    getDocuments,
    downloadDocument,
    getBolStatusFromRedis,
    getDocumentInfoByHash,
    getShInfo,
    signCA,
    signCRA,
    downloadCRA,
    getTrackingInfoForWidget,
    editShipmentName,
    uploadImage,
    getProductImages,
    deleteImage
};
